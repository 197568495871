import styles from './Rozklad.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useState, useEffect } from "react";
import "swiper/css/pagination";
import shedule1 from './shedule1.webp'
// import shedule2 from './shedule2.webp'
// import shedule3 from './shedule3.webp'
import shedule4 from './shedule4.webp'
import shedule5 from './shedule5.webp'
// import shedule6 from './shedule6.webp'
// import shedule7 from './shedule7.webp'
import { Pagination } from 'swiper/modules';

export default function Rozklad() {
  const [slidesPerView, setSlidesPerView] = useState(1);
  const updateSlidesPerView = () => {
    if (window.innerWidth > 768) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(1);
    }
  };
  // Отслеживаем изменение ширины окна
  useEffect(() => {
    updateSlidesPerView(); // Устанавливаем количество слайдов при первой загрузке
    window.addEventListener("resize", updateSlidesPerView); // Добавляем обработчик изменения размера окна

    return () => {
      window.removeEventListener("resize", updateSlidesPerView); // Убираем обработчик при размонтировании компонента
    };
  }, []);
  const shedulesObj = [
    {
      img: shedule1,
      classIMG: "shedule1",
      date: "23.03",
      info: "заїзд",
    },
    {
      img: shedule4,
      classIMG: "shedule2",
      date: "24-27.03",
      info: "робота в колі жінок",
    },
    {
      img: shedule5,
      classIMG: "shedule7",
      date: "28.03",
      info: "виїзд",
    },
  ];

  return (
    <section className={styles.sectionRozklad} id='rozklad'>
      <div className="container">
        <h2 className={styles.titleRozklad}>розклад ІНТЕНСИВУ</h2>
        <Swiper
          className={styles.swiperRozkladRetrite}
          slidesPerView={slidesPerView}
          spaceBetween={40}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          autoHeight={true}
        >
          {shedulesObj.map((card, index) => (
            <SwiperSlide
              key={index}
              className={styles.classIMG}
              style={{
                background: `linear-gradient(rgba(20, 11, 3, 0.8), rgba(20, 11, 3, 0.8)), url(${card.img})`,
                backgroundSize: "cover",
                padding: "200px 20px",
                borderRadius: "20px",
              }}
            >
              <p className={styles.titleDateCard}>{card.date}</p>
              <p className={styles.textInfoCard}>{card.info}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}