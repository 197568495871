import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./SpeakerInfo.module.css";
import sprite from "../../icons.svg";

// import firstImage from "./firstImage.webp";
import secondImage from "./secondImage.webp";
import thirdImage from "./thirdImage.webp";
import fourthImage from './_W7B3698.webp'
export const SpeakerInfo = () => {
  return (
    <section className={styles.section} id="speaker">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>АНАСТАСія-олімпія матюшевська</h2>
        <div className={styles.contentContainer}>
          <Swiper
            className={styles.swiper}
            pagination={true}
            modules={[Pagination]}
            slidesPerView={1}
            spaceBetween={40}
          >
            <SwiperSlide>
              <img className={`${styles.swiperImage} ${styles.swiperImageF}`} src={fourthImage} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className={styles.swiperImage} src={secondImage} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img className={styles.swiperImage} src={thirdImage} alt="" />
            </SwiperSlide>
          </Swiper>
          <div className={styles.speakerInfoContainer}>
            <div className={styles.speakerInfoTextWrapper}>
              <svg className={styles.speakerInfoicon} width={620} height={420}>
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>
              <p className={styles.speakerInfoText}>
                Анастасія-Олімпія Матюшевська - акредитований гештальт-терапевт
                НАГТУ, супервізор, акредитований член EAGT (европейська
                асоціація гештальт терапевтів), спеціаліст по роботі з
                сексуально тематикою
              </p>
            </div>
            <div className={styles.speakerInfoTextWrapper}>
              <svg className={styles.speakerInfoicon} width={620} height={420}>
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>
              <p className={`${styles.speakerInfoText} ${styles.textNoWrap}`}>
                Ведуча авторських курсів по Сексології та Військовій
                психотерапії; Засновниця проєктів психологічно-емоційного
                розвитку &quot;ЖІНКИ&quot;: конференції, ретрит тощо
              </p>
            </div>
            <div className={styles.speakerInfoTextWrapper}>
              <svg className={styles.speakerInfoicon} width={620} height={420}>
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>
              <p className={`${styles.speakerInfoText}`}>
                Практичний досвід роботи в психотерапії ~16 років, і сексології
                ~ 11 років.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
