import styles from "./About.module.css";
import firstImage from "./firstImage.webp";
import secondImage from "./secondImage.webp";
import thirdImage from "./thirdImage.webp";
import beachImage from "./beachImage.webp";
import secondBeachImage from "./secondBeachImage.webp";

export const About = () => {
  return (
    <section className={styles.section} id="about">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>про ретрит</h2>
        <p className={styles.mainText}>
          «Жінки. Сексрозкуті» - Це простір, де ти можеш безпечно пройти шлях
          прийняття та відкриття своєї справжньої природи.
          <span className={styles.mainTextContent}>
            <img
              src={firstImage}
              className={styles.mainTextImg}
              alt="Decorative image"
            />
          </span>
          Це не про те, щоб стати «фатальною жінкою» або робити феєрверк у
          ліжку. Це про внутрішню свободу, про сексуальність без нав`язаних
          шаблонів і страхів
          <img
            src={secondImage}
            className={styles.mainTextImg}
            alt="Decorative image"
          />
          Ми говоритимемо про: Сексуальність і жіноче проявлення. Активність,
          ініціативу та їхні виклики. Обмеження, які відчуває жінка поряд з
          іншими жінками та партнерами. Вікові зміни та їхній вплив на
          сексуальність.
          <img
            src={thirdImage}
            className={styles.mainTextImg}
            alt="Decorative image"
          />
        </p>
        <div className={styles.introducingContainer}>
          <img
            src={beachImage}
            className={styles.introducingImage}
            alt="Women sitting on the beach"
          />
          <p className={styles.introducingText}>
            Я, Анастасія-Олімпія Матюшевська, і перш за все{" "}
            <span className={styles.highlightedText}>я-жiнка!</span>
            <span className={styles.imageDecorationLine}></span>
          </p>
        </div>
        <div className={`${styles.introducingContainer} ${styles.reversed}`}>
          <img
            src={secondBeachImage}
            className={styles.introducingImage}
            alt="Women sitting on the beach"
          />
          <p className={styles.introducingText}>
            Тому запрошую тебе до нашого{" "}
            <span className={`${styles.highlightedText} ${styles.reversed}`}>
              жiночого очного кола!
            </span>
            <span
              className={`${styles.imageDecorationLine} ${styles.reversed}`}
            ></span>
          </p>
        </div>
      </div>
    </section>
  );
};
