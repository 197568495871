import styles from "./OurConference.module.css";
import { useState } from "react";
import { MoreQuestions } from "../../Conference/HFM/Modals/Modules";
import { ChoiseTariffModal } from "../../Conference/HFM/Modals/ChoiseTariffModal";
import { Link } from "react-router-dom";
import sprite from "../../icons.svg";

export default function OurConference() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  const [modalMoreQuestionsIsOpen, setModalMoreQuestionsOpen] = useState(false); // Стан для модального вікна MoreQuestions

  const upcomingJson = [
    {
      titleCardF: "ПРО АГРЕСІЮ: жінок, ",
      titleCardS: "до жінок, біля жінок",
      dateCard: "30 червня - 1 липня 2024",
      btnCard: "Придбати записи",
      btnClass: "whiteBtn",
      link: "/aggression",
      classCard: styles.wrapperInformationNextConferenceBefore,
    },
    {
      titleCardF: "флірт, кокетство, ",
      titleCardS: "любовна гра",
      dateCard: "10 - 11 вересня 2024",
      btnCard: "Придбати записи",
      btnClass: "whiteBtn",
      link: "/flirt",
      classCard: styles.wrapperInformationNextConferenceBefore,
    },
    {
      titleCardF: "КОРДОНИ ТА МЕЖІ Заборони та їх порушення",
      dateCard: "27-28 жовтня",
      btnCard: "Придбати записи",
      btnClass: "whiteBtn",
      link: "/prohibition",
      classCard: styles.wrapperInformationNextConferenceBefore,
    },
    {
      titleCardF: "Турбота та здатність турбуватись",
      dateCard: "11 - 12  січня 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalPrizeOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Підглядання та показування",
      dateCard: "4 - 5 березня 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Тема конференції зʼявиться найближчим часом",
      dateCard: "травень 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Переживання смутку ",
      titleCardS: "та здатність засмучуватись",
      dateCard: "липень 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Вірність, відданість",
      titleCardS: "та єдиність жінки та до жінок",
      dateCard: "вересень 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Неперервність",
      dateCard: "листопад 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Тіло",
      dateCard: "січень 2026",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "Оргазм та оргастичні",
      titleCardS: "переживання жінки",
      dateCard: "березень 2026",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
    {
      titleCardF: "конференція - сюрприз",
      dateCard: "травень 2026",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: styles.wrapperInformationNextConference,
    },
  ];

  return (
    <section className={styles.ourConferenceSection}>
      <div className="container">
        <h2 className={styles.titleOurConference}>
          <svg className={styles.ourConferenceSVG} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-client`}></use>
          </svg>
          Наші конференції
        </h2>
        <ul className={styles.wrapperOurConferenceList}>
          {upcomingJson.map((coming, index) => (
            <li key={index} className={styles.wrapperOneSlide}>
              <article className={coming.classCard}>
                <h3 className={styles.titleInfoNextConference}>
                  {coming.titleCardF} <br /> {coming.titleCardS}
                </h3>
                <p className={styles.textDateNextConference}>
                  {coming.dateCard}
                  {/* <br /> платформа зум */}
                </p>
                <Link to={coming.link}>
                  <button className={coming.btnClass} onClick={coming.click}>
                    {coming.btnCard}
                  </button>
                </Link>
              </article>
            </li>
          ))}
        </ul>
      </div>

      <MoreQuestions
        isOpen={modalMoreQuestionsIsOpen}
        onClose={() => setModalMoreQuestionsOpen(false)}
      />
      <ChoiseTariffModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      />
    </section>
  );
}
