import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./Program.module.css";
import sprite from "../../icons.svg";
import { imagesData } from "./imagesData.js";
export const Program = () => {
  return (
    <section className={styles.section} id="programm">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>програма інтенсиву</h2>
        <Swiper
          className={`${styles.swiper} ${styles.onlyOnMobile}`}
          pagination={true}
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={40}
        >
          <SwiperSlide className={styles.swiperSlide}>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard1})` }}
            >
              <p className={styles.slideText}>Пʼять днів у колі жінок</p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard2})` }}
            >
              <p className={styles.slideText}>Арт- та тілесна терапія</p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard3})` }}
            >
              <p className={styles.slideText}>Ранкові ритуали</p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard4})` }}
            >
              <p className={styles.slideText}>Розмови, інколи таємні</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard5})` }}
            >
              <p className={styles.slideText}>
                Ритуали <br /> жіночності
              </p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard6})` }}
            >
              <p className={styles.slideText}>Вечори біля вогнища</p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard7})` }}
            >
              <p className={styles.slideText}>
                Активні та романтичні екскурсії
              </p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard8})` }}
            >
              <p className={styles.slideText}>
                Ігри, танці, <br /> розваги та <br /> деколи обійми
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard9})` }}
            >
              <p className={styles.slideText}>
                Купання <br /> у відкритому морі лазуру з власної яхти, та
                вечерею <br />
                на острові
              </p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard10})` }}
            >
              <p className={styles.slideText}>
                Проживання <br /> в готелі/віллі
              </p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard11})` }}
            >
              <p className={styles.slideText}>
                зустріч з експертом етикету та жіночого аристократизму
              </p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard12})` }}
            >
              <p className={styles.slideText}>Випускний</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard13})` }}
            >
              <p className={styles.slideText}>
                Особливий <br /> дрес-код
              </p>
            </div>
            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard14})` }}
            >
              <p className={styles.slideText}>
                Арт-завдання <br /> з секретним сенсом безсвідомого
              </p>
            </div>

            <div
              className={styles.slideCard}
              style={{ backgroundImage: `url(${imagesData.slideCard16})` }}
            >
              <p className={styles.slideText}>Фоторепортаж зустрічей</p>
            </div>
          </SwiperSlide>
        </Swiper>

        <Swiper
          className={`${styles.swiper} ${styles.onlyOnDekstop}`}
          modules={[Navigation]}
          slidesPerView={1}
          spaceBetween={40}
          navigation={{
            prevEl: `.${styles.prevButton}`,
            nextEl: `.${styles.nextButton}`,
          }}
        >
          <SwiperSlide className={styles.swiperSlide}>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard1}
                alt="Три дводенки з Анастасією"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Пʼять днів у колі жінок</p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard2}
                alt="Арт- та тілесна терапія"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Арт- та тілесна терапія</p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard3}
                alt="ранкові ритуали"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Ранкові ритуали</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard4}
                alt="Розмови, інколи таємні"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Розмови, інколи таємні</p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard5}
                alt="Ритуали жіночності"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>
                Ритуали <br /> жіночності
              </p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard6}
                alt="Вечори біля вогнища"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Вечори біля вогнища</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard7}
                alt="Активні та романтичні екскурсії"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>
                Активні та романтичні екскурсії
              </p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard8}
                alt="Ігри, танці, розваги та деколи обійми"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>
                Ігри, танці, розваги та деколи обійми
              </p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard9}
                alt="Купання у відкритому морі лазуру з власної яхти, та вечерею на острові"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>
                Купання у відкритому морі лазуру з власної яхти, та вечерею на
                острові
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard10}
                alt="Проживання в готелі/віллі"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Проживання в готелі/віллі</p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard11}
                alt="Лекція від топ відомих еспертів краси та здоровʼя"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>
                Зустріч з експертом етикету та жіночого аристократизму
              </p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard12}
                alt="Випускний"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Випускний</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiperSlide}>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard13}
                alt="Особливий дрес-код"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Особливий дрес-код</p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard14}
                alt="Арт-завдання з секретним сенсом безсвідомого"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>
                Арт-завдання з секретним сенсом безсвідомого
              </p>
            </div>
            <div className={styles.slideCard}>
              <img
                src={imagesData.slideCard16}
                alt="Фоторепортаж зустрічей"
                className={styles.slideImage}
              />
              <p className={styles.slideText}>Фоторепортаж зустрічей</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className={`${styles.swiperNavigation} ${styles.onlyOnDekstop}`}>
          <button className={styles.prevButton}>
            <svg width={35} height={35} fill="white">
              <use xlinkHref={`${sprite}#icon-doubleLeftArrow`}></use>
            </svg>
          </button>
          <button className={styles.nextButton}>
            {" "}
            <svg width={35} height={35} fill="white">
              <use xlinkHref={`${sprite}#icon-doubleRightArrow`}></use>
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};
