import styles from "./Comment.module.css";

export const Comment = () => {
  return (
    <section className={styles.section} id="comment">
      <div className={styles.commentContainer}>
        <p className={styles.commentText}>
          Словами важко передати, наскільки дбайливо та своєчасно вибудуваний
          кожен процес, але що точно можу сказати — це буде глибокий досвід,
          розмови, які змінюють, і трансформації, які залишаються з тобою
          назавжди.
        </p>
      </div>
    </section>
  );
};
