import styles from "./Price.module.css";
import sprite from "../../icons.svg";
import { BookingModal } from "../BookingModal/BookingModal";

export const Price = () => {
  return (
    <section className={styles.section} id="price">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>вартість</h2>
        <div className={styles.cardsContainer}>
          <div className={styles.card}>
            <p className={styles.cardTitle}>До 07.03.2025</p>
            <div className={styles.cardDescriptionContainer}>
              <svg
                className={styles.cardDescriptionIcon}
                width={620}
                height={420}
              >
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>
              <p>7000€ – участь, розважальна програма та проживання</p>
            </div>
            <p className={styles.cardPrice}>
              <span className={styles.cardPriceBefore}> 7000€ </span>
              4000€
            </p>
          </div>
          <div className={`${styles.card} ${styles.whiteCard}`}>
            <p className={styles.cardTitle}>До 22.03.2025</p>
            <div className={styles.cardDescriptionContainer}>
              <svg
                className={styles.cardDescriptionIcon}
                width={620}
                height={420}
              >
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>

              <p>7500€ – участь, розважальна програма та проживання</p>
            </div>
            <p className={styles.cardPrice}>
              <span className={styles.cardPriceBefore}> 7500€ </span>
              4500€
            </p>
          </div>
        </div>
        <BookingModal />
      </div>
    </section>
  );
};
